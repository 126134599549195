
import { defineComponent } from "vue";
import { PresenceController, SessionController, TimeEntryController, WorkbreakController } from "@/controller/";
import { Employee, Presence, TimeEntry, Workbreak } from "@/model";
import { TimeEntryCard }  from "@/components/index";
import { Converter, DateTimeUtils } from "@/utils";

export default defineComponent({
    name: "DailyReport",
    components: { TimeEntryCard },
    props: {
        timeentries: {
            type: Array,
            default: null
        }
    },
    data(){
        return{
            sessionEmployee: {} as Employee,
            timeEntries: [] as Array<TimeEntry>,
            activePresence: {} as Presence,
            workbreaks: [] as Array<Workbreak>,
            activeDay: Math.floor(Date.now() / 1000)
        }
    },
    created(){
        this.getSessionUser();
    },
    computed: {
        timeSum() {
            let sum = 0;
            this.timeEntries.forEach((entry : any) => {
                sum += entry.getTimeGone()
            })
            return sum;
        },
        latestInteraction(){
            let last = 0;
            this.timeEntries.forEach((entry : any) => {
                if (entry.tstamp > last) {
                    last = entry.tstamp;
                }
            })
            return this.convertToTime(last);
        },
        presenceTime() {
            if (this.activePresence.go) {
                return this.activePresence.go - this.activePresence.come
            } else {
                console.log('presenceTime', this.activePresence.come, new Date().valueOf(), new Date().valueOf() / 1000- this.activePresence.come);
                return Math.floor(new Date().valueOf() / 1000) - this.activePresence.come
            }
        }
    },
    methods: {
        convertToTime(ts:number){
            return Converter.convertTimeStampToTime(ts)
        },
        convertTimeStampToTime(ts: number) {
            return Converter.convertTimeStampToTime(ts)
        },
        convertSecondsToTime(ts:number){
            return Converter.convertSecondsToTime(ts)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },

        getSessionUser(){
            //this.sessionUser = await SessionController.sessionUser 
            this.sessionEmployee = SessionController.sessionEmployee;
            this.getActivePresence();
            this.getTimeEntries();
            this.getWorkbreaks();
        },
        async getTimeEntries() {
            const res = await TimeEntryController.fetchDayTimeEntries(this.sessionEmployee.uid, this.activeDay)
            if(!res.error){
                this.timeEntries = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getActivePresence(){
            this.activePresence = await PresenceController.getActivePresence(this.sessionEmployee.uid)
        },
        async getWorkbreaks() {
            const response = await WorkbreakController.getDayWorkbreaks(this.sessionEmployee.uid, Math.floor(new Date().valueOf() / 1000))
            if (response.items) {
                this.workbreaks = response.items
            }
            console.log('getWorkbreaks', this.workbreaks)
        }

    },
})
