import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "timi-dailyreport-container" }
const _hoisted_2 = { class: "timi-dailyreport-content" }
const _hoisted_3 = { class: "statistic-wrapper" }
const _hoisted_4 = { class: "statistic-item" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "statistic-item" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "statistic-item" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "statistic-item" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = {
  key: 0,
  class: "statistic-item"
}
const _hoisted_13 = { class: "list-workbreaks" }
const _hoisted_14 = { class: "workbreak-from" }
const _hoisted_15 = { class: "workbreak-to" }
const _hoisted_16 = { class: "workbreak-to" }
const _hoisted_17 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeEntryCard = _resolveComponent("TimeEntryCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('day.dailyStatistic')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('day.presenceTime')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.convertSecondsToTime(_ctx.presenceTime)), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('day.workingtimeSUM')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.convertSecondsToTime(_ctx.timeSum)), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('day.comeAt')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.convertToTime(_ctx.activePresence.come) + " " + _ctx.$t('label.oClock')), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('day.goAt')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.activePresence.go ? _ctx.convertToTime(_ctx.activePresence.go) + " " + _ctx.$t('label.oClock') : "--"), 1)
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('day.lastInteractivity')) + ":", 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.latestInteraction + " " + _ctx.$t('label.oClock')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('day.workbreaks')), 1),
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workbreaks, (workbreak) => {
          return (_openBlock(), _createElementBlock("div", {
            key: workbreak.uid,
            class: "list-entry-workbreak"
          }, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('label.workbreakStart')) + " " + _toDisplayString(_ctx.convertTimeStampToTime(workbreak.start)), 1),
            _createTextVNode(),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('label.workbreakStop')) + " " + _toDisplayString((workbreak.stop ? _ctx.convertTimeStampToTime(workbreak.stop) : '-')), 1),
            _createTextVNode(),
            _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('label.workbreakPaused')) + " " + _toDisplayString((workbreak.getTimePaused() ? _ctx.convertSeconds(workbreak.getTimePaused()) : '-')), 1)
          ]))
        }), 128)),
        (!_ctx.workbreaks.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.workbreakNone')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('day.activities')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeEntries, (entry) => {
        return (_openBlock(), _createBlock(_component_TimeEntryCard, {
          key: entry.uid,
          entry: entry
        }, null, 8, ["entry"]))
      }), 128))
    ])
  ]))
}